import React, { ReactElement } from "react"
import { Image } from "../../../types/images"
import WorkDetail from "../../components/WorkDetail"

const imgs: Image[] = [
  {
    src: "Imagewear/find_a-store_caahhw.jpg",
    alt: "Find a Store",
    id: "1",
    chrome: true,
  },
  {
    src: "Imagewear/guides_hdfba3.jpg",
    alt: "Our Guide",
    id: "2",
    chrome: true,
  },
  {
    src: "Imagewear/product_y48ncf.jpg",
    alt: "Product Detail",
    id: "3",
    chrome: true,
  },
  {
    src: "Imagewear/categories.png_g2qvxf.jpg",
    alt: "Landing",
    id: "4",
    chrome: true,
  },
]

export default function Imagewear(): ReactElement {
  return (
    <WorkDetail title="Imagewear" images={imgs}>
      <p className="lead">
        Imagewear supplies thousands of businesses across Canada with compliant
        industrial workwear, footwear as well as branded uniforms and apparel.
      </p>

      <p>
        The goal for this site was to offer a more e-commerce like way of
        browsing the products that Imagewear has to offer. This site was used to
        augment the product guide, which had traditionally been a printed
        catalogue that needed to be delivered to businesses.
      </p>
    </WorkDetail>
  )
}
